// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayPathBuilder, CloudRunPathBuilder, Env, ICandidateEnvironment } from 'shared-lib'

const CANDIDATE_BASE_URL = 'https://ci-network.cnected.com'

const env: Env = 'ci'
const projectNumber = '524673897104'
const pathBuilder = new ApiGatewayPathBuilder(env)
const cloudRunPathBuilder = new CloudRunPathBuilder('wftea2wuka')

export const environment: ICandidateEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: 'AIzaSyBJnAEalpY0inF_NHTc8vC2Gk5GFNHdqx4',
    authDomain: 'cnect-ci.firebaseapp.com',
    projectId: 'cnect-ci',
    storageBucket: 'cnect-ci.appspot.com',
    messagingSenderId: projectNumber,
    appId: '1:524673897104:web:8c1ac11f93d181ae47fe15',
    measurementId: 'G-DG70NW57FV',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-ci.cloudfunctions.net',
    jobs: pathBuilder.build('jobs'), // 'https://ci-api.cnected.com/jobs-api-gateway',
    jobsAutomation: pathBuilder.build('jobsAutomation'), // 'https://ci-api.cnected.com/jobs-automation-api-gateway',
    registration: pathBuilder.build('registration'), // https://ci-api.cnected.com/registration-api-gateway',
    events: pathBuilder.build('events'), // https://ci-api.cnected.com/events-api-gateway',
    applicationHistory: pathBuilder.build('applicationHistory'), // https://ci-api.cnected.com/application-history-api-gateway',
    content: pathBuilder.build('content'), // https://ci-api.cnected.com/content-api-gateway',
    candidate: pathBuilder.build('candidate'), // https://ci-api.cnected.com/candidate-api-gateway',
    employer: pathBuilder.build('employer'), // https://ci-api.cnected.com/employer-api-gateway',
    auth: pathBuilder.build('auth'), // https://ci-api.cnected.com/auth-api-gateway',
    files: pathBuilder.build('files'), // https://ci-api.cnected.com/files-api-gateway',
    messaging: pathBuilder.build('messaging'), // https://ci-api.cnected.com/messaging-api-gateway',
    community: pathBuilder.build('community'), // https://ci-api.cnected.com/community-api-gateway',
    virtualDialogue: pathBuilder.build('virtualDialogue'), // https://ci-api.cnected.com/virtual-dialogue-api-gateway',
    access: pathBuilder.build('access'), // https://ci-api.cnected.com/access-api-gateway',
    social: pathBuilder.build('social'), // https://ci-api.cnected.com/social-api-gateway',
    notifications: pathBuilder.build('notifications'), // https://ci-api.cnected.com/notifications-api-gateway',
    user: pathBuilder.build('user'),
    sse: cloudRunPathBuilder.build('sse', false),
    product: pathBuilder.build('product'),
  },
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcQh4scAAAAAGFHzKO0pMDwtTDA9cAquJaGJ8L8',
  tenantId: 'B2C-USER-eenqh',
  cdnCname: 'ci-content.cnected.com',
  basePlatformHost: 'ci-network.cnected.com',
  algoliaAppId: 'W2R5M3E540',
  version: '02dfe44',
  employerEmailActionRoute: 'http://ci-employer.cnected.com/#/auth/actions',
  candidateEmailSuccessRoute: 'https://ci-network.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  emailVerificationEmailTemplateId: 'cwdpiIuZAP8dPQqB5s5K',
  betaMessageId: 'FoyYlvOzSxbxbMvU2ykE',
  candidateBaseUrl: CANDIDATE_BASE_URL,
  appDownloadDynamicLink: 'https://cnect.page.link/uEsh',
  tenant: {
    appConfigBaseURL: 'https://ci-content.cnected.com/assets/bootstrap',
    defaultTenant: 'ci-network.cnected.com',
  },
  smartBannerOptions: {
    appIcon: 'https://play-lh.googleusercontent.com/W170UOarQtwNy4GY1JXosU6SD3LBY5OzTYOWsRUL9W6yXYwPoCeUVGeTtuO850ByjSw=w480-h960-rw',
    appStoreUrl: 'https://apps.apple.com/us/app/cnected/id6467040985',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.e11.prod.cnect',
    ttl: `${24 * 3.6e6}`,
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
